import { useDispatch, useSelector } from 'react-redux'
import { authSelectors } from '@ucheba/store/auth'
import { NextRouter, useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getSingleQuery, stringifyUrl } from '@ucheba/utils/helpers/router'
import {
  authPhoneCodeActions,
  authPhoneCodeSelectors,
  authPhoneCodeThunks,
} from '@ucheba/store/auth/phone/code'
import {
  clientProductsRequestsActions,
  clientProductsRequestsSelectors,
  clientProductsRequestsThunks,
} from '@ucheba/store/client-products/requests'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { useTimer } from '@ucheba/utils/hooks/useTimer'
import { ELoading } from '@ucheba/store/utils/response/types'
import { getIn, useFormikContext } from 'formik'
import { authPhoneSelectors } from '@ucheba/store/auth/phone'
import { EClientProductsRequestsDataKeys } from '@ucheba/store/client-products/requests/types'
import { profileActions, profileSelectors, profileThunks } from '@ucheba/store/profile'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import { eventsIdSelectors, eventsIdThunks } from '@ucheba/store/events/id'
import { useLocalStorage } from '@ucheba/utils/hooks/useLocalStorage'
import {
  getCookieDomain,
  getParsedCookies,
  isBrowser,
  setCookie,
} from '@ucheba/utils/helpers/core'
import { EChatBotScenario } from '@ucheba/store/chat-bot/types'
import dayjs from '@ucheba/utils/helpers/date/dayjs'
import { getVisitInfo } from '@ucheba/utils/hooks/useVisitsTracker'
import { SCHOOL_SITE_NAME, UCHEBA_SITE_NAME } from '@ucheba/utils/constants/core'
import { siteNameSelectors } from '@ucheba/store/siteName'
import { codeLength, scenariosByReqTargets } from './constants'
import {
  EApplicationFormProductTypes,
  EApplicationFormQuery,
  EApplicationFormSteps,
  EApplicationFunnels,
  IUseApplication,
  IUseApplicationFormCodeCore,
  IUseApplicationFormParentCore,
  IUseApplicationFormRequestCore,
  IUseSelectProduct,
} from './types'
import {
  ERequestQuery,
  ERequestSource,
  ETypeRequest,
} from '../../pages/for-abiturients/ege/index/types'
import { ENoticePermissionQueryType } from '../VkPermissionNotice/types'
import { EPaymentQuery } from '../../pages/payment/index/types'

const specialOfferCodeKey = 'specialOfferCode'
const lovkoProClickIdKey = 'lovkoProClickId'
const edpartnersClickIdKey = 'edpartnersClickId'

export const useReferralCode = (referralCodeKey: string): string => {
  const router = useRouter()
  const parsedCookies = getParsedCookies()
  const [referralCode, setReferralCode] = useState(parsedCookies[referralCodeKey] || null)
  useEffect(() => {
    const referralCodeFromQuery =
      getSingleQuery(router.query[ERequestQuery.referralCode]) || null
    if (referralCodeFromQuery !== null) {
      setReferralCode(referralCodeFromQuery)
      const expiresCalc = dayjs().add(1, 'year').toDate()
      setCookie(referralCodeKey, referralCodeFromQuery, {
        expires: expiresCalc,
        domain: getCookieDomain(),
        httpOnly: false,
      })
    }
  }, [router.query])
  return referralCode
}

export const useSpecialOffer = (): string => {
  const router = useRouter()
  const [specialOfferCode, setSpecialOfferCode] = useLocalStorage(specialOfferCodeKey)
  useEffect(() => {
    const specialOfferCodeFromQuery = router.query[ERequestQuery.specialOffer] || null
    if (specialOfferCodeFromQuery !== null) {
      setSpecialOfferCode(specialOfferCodeFromQuery)
    }
  }, [router.query])
  return specialOfferCode
}

export const useLovkoProClickId = (): string => {
  const router = useRouter()
  const [lovkoProClickId, setLovkoProClickId] = useLocalStorage(lovkoProClickIdKey)
  useEffect(() => {
    const lovkoProClickIdFromQuery = router.query[ERequestQuery.lovkoProClickId] || null
    if (lovkoProClickIdFromQuery !== null) {
      setLovkoProClickId(lovkoProClickIdFromQuery)
    }
  }, [router.query])
  return lovkoProClickId
}

export const useEdPartnersClickId = (): string => {
  const router = useRouter()
  const [edPartnersClickId, setEdPartnersClickId] = useLocalStorage(edpartnersClickIdKey)
  useEffect(() => {
    const fromQuery = router.query[ERequestQuery.edpartnersClickId] || null
    if (fromQuery !== null) {
      setEdPartnersClickId(fromQuery)
    } else if (router.query.a) {
      // Код взят из реккомендаций партнёра
      const availableParams = [
        'aff_click_id',
        'sub_id1',
        'sub_id2',
        'sub_id3',
        'sub_id4',
        'sub_id5',
        'aff_param1',
        'aff_param2',
        'aff_param3',
        'aff_param4',
        'aff_param5',
        'idfa',
        'gaid',
      ]
      const t = new URL('https://edpartners.scaletrk.com/click')
      const r = t.searchParams
      r.append('a', router?.query?.a as string)
      r.append('o', router?.query?.o as string)
      r.append('return', 'click_id')
      if (availableParams?.length > 0) {
        availableParams.forEach((key) => {
          const value = router.query[key]
          if (value) {
            r.append(key, value as string)
          }
        })
      }
      fetch(t.href)
        .then((e) => e.json())
        .then((e) => {
          if (e.click_id) {
            setEdPartnersClickId(e.click_id)
          }
        })
    }
  }, [router.query])
  return edPartnersClickId
}

const useReqAnalytics = (requestTarget?: string, loading?: string): void => {
  const router = useRouter()
  useEffect(() => {
    const isStepCode = router.query.step === EApplicationFormSteps.code

    if (ELoading.fulfilled === loading && !isStepCode) {
      switch (requestTarget) {
        case ETypeRequest.ege_lead_request:
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.egeLeadRequest,
            section: EAnalyticSections.requestForm,
          })
          break

        case ETypeRequest.ege_lead_consult:
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.egeLeadConsult,
            section: EAnalyticSections.requestForm,
          })
          break

        case ETypeRequest.ege_lead_free_lesson:
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.egeLeadLesson,
            section: EAnalyticSections.requestForm,
          })
          break

        case ETypeRequest.ege_lead_free_test:
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.egeLeadTest,
            section: EAnalyticSections.requestForm,
          })
          break

        case 'oge_lead_free_lesson':
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.ogeLeadLesson,
            section: EAnalyticSections.requestForm,
          })
          break

        case 'oge_lead_free_test':
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.ogeLeadTest,
            section: EAnalyticSections.requestForm,
          })
          break

        case 'oge_lead_consult':
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.ogeLeadConsult,
            section: EAnalyticSections.requestForm,
          })
          break

        case 'oge_lead_request':
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.ogeLeadRequest,
            section: EAnalyticSections.requestForm,
          })
          break

        case EApplicationFormProductTypes.proforientation:
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.profLeadRequest,
            section: EAnalyticSections.requestForm,
          })
          break

        // eslint-disable-next-line sonarjs/no-duplicated-branches
        case EApplicationFormProductTypes.proforientationRequest:
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.profLeadRequest,
            section: EAnalyticSections.requestForm,
          })
          break

        case EApplicationFormProductTypes.admissionSupport:
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.admissionSupportLeadRequest,
            section: EAnalyticSections.requestForm,
          })
          break

        default:
      }
    }
  }, [loading, router.query.step, requestTarget])
}

/* Хук для использования компонента во внешней среде */
export const useApplication: IUseApplication = () => {
  const egeReferralCodeKey = 'egeReferralCode'
  const router = useRouter()

  const { query } = router

  const product = useMemo(() => {
    return {
      productId: Number(query.productId) || undefined,
      count: Number(query.count) || undefined,
    }
  }, [query.count, query.productId])

  const funnel = useMemo(() => {
    const value = getSingleQuery(query[EApplicationFormQuery.funnel])
    if (!value) {
      return null
    }
    return EApplicationFunnels[value]
  }, [query])

  const requestTarget = useMemo(() => {
    return getSingleQuery(query[EApplicationFormQuery.requestTarget]) || null
  }, [query])

  const subjectsId = useMemo(() => {
    return Number(query[EApplicationFormQuery.subjectId]) || undefined
  }, [query])

  const eventsId = useMemo(() => {
    return Number(query[EApplicationFormQuery.eventsId]) || undefined
  }, [query])

  const isApplication = useMemo(() => {
    return Boolean(router.query[EApplicationFormQuery.application])
  }, [router.query])

  const closeApp = useCallback(() => {
    router.replace(
      router.asPath.split(router.query.offer ? `?${router.query.offer}` : '?')[0],
      undefined,
      {
        shallow: true,
        scroll: false,
      }
    )
  }, [])

  const referralCode = useReferralCode(egeReferralCodeKey)
  const specialOfferCode = useSpecialOffer()
  useLovkoProClickId()
  useEdPartnersClickId()

  return {
    product,
    isApplication,
    funnel,
    requestTarget,
    subjectsId,
    eventsId,
    closeApp,
    referralCode,
    specialOfferCode,
  }
}

/* Отправляет на нужный шаг */
export const pushToStep = (router: NextRouter, query: Record<string, any>): void => {
  const currentStep = router.query[EApplicationFormQuery.step]

  router[currentStep === EApplicationFormSteps.code ? 'replace' : 'push'](
    stringifyUrl(router.asPath, {
      update: query,
    }),
    undefined,
    { shallow: true, scroll: false }
  )
}

/* Возвращает главный роут заявки */
export const useRequestStep = (): string => {
  const router = useRouter()

  return useMemo(
    () =>
      stringifyUrl(router.asPath, {
        exclude: [EApplicationFormQuery.step],
      }),
    [router.asPath]
  )
}

/* Если не авторизован, отправляет на первый шаг */
export const useAuthGuard = (): void => {
  const isAuth = useSelector(authSelectors.isAuth)
  const router = useRouter()
  const requestStep = useRequestStep()

  useEffect(() => {
    if (!isAuth) {
      router.replace(requestStep, undefined, { shallow: true, scroll: false })
    }
  }, [isAuth, requestStep, router])
}

/* Сбрасывает лодинги при инициализации */
export const useResetProfileAndRequestLoadings = (): void => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { query } = router

  /* Сбрасываем лодинг при инициализации */
  useEffect(() => {
    if (!query.error) {
      dispatch(profileActions.resetLoading())
      dispatch(clientProductsRequestsActions.resetError())
      dispatch(clientProductsRequestsActions.resetLoading())
    }
  }, []) // не добавлять зависимости
}

/* Возвращает название текущего шага */
export const useStepName = (): string => {
  const { query } = useRouter()

  return useMemo(() => {
    const currentStepName = query[EApplicationFormQuery.step]

    return currentStepName
      ? EApplicationFormSteps[
          Array.isArray(currentStepName) ? currentStepName[0] : currentStepName
        ]
      : ''
  }, [query])
}

export const useApplicationFormRequestCore: IUseApplicationFormRequestCore = ({
  count,
  productId,
  productType,
  funnel,
  requestTarget,
  subjectId,
  referralCode,
  specialOfferCode,
  aboutParent,
}) => {
  const dispatch = useDispatch()
  const code = useSelector(authPhoneSelectors.code)
  const clientProductsRequestsEntity = useSelector(clientProductsRequestsSelectors.entity)
  const clientProductsRequestsLoading = useSelector(
    clientProductsRequestsSelectors.loading
  )
  const router = useRouter()
  const [formValuesOnSubmit, setFormValuesOnSubmit] = useState<any>({})
  const lovkoProClickId = useLovkoProClickId()
  const edPartnersClickId = useEdPartnersClickId()
  const { query } = router

  useResetProfileAndRequestLoadings()

  /* Если отправка прошла успешно */
  const isSendSuccess = useMemo(
    () => clientProductsRequestsLoading === ELoading.fulfilled,
    [clientProductsRequestsLoading]
  )

  /* Формируем значения формы по умолчанию */
  const initialValues = useMemo(() => {
    return {
      [EDataKeys.name]: query.name || '',
      [EDataKeys.phone]: query.phone || '',
      [EDataKeys.email]: query.email || '',
      [EDataKeys.code]: code,
      [EDataKeys.isParent]: null,
      [EDataKeys.specialOfferCode]: specialOfferCode,
      [EClientProductsRequestsDataKeys.productType]: productType,
      [EClientProductsRequestsDataKeys.count]: count || null,
      [EClientProductsRequestsDataKeys.productId]: productId || null,
      [EClientProductsRequestsDataKeys.funnel]: funnel,
      [EClientProductsRequestsDataKeys.requestTarget]: requestTarget,
      [EClientProductsRequestsDataKeys.referralCode]: referralCode,
      [EClientProductsRequestsDataKeys.lovkoProClickId]: lovkoProClickId,
      [EClientProductsRequestsDataKeys.edPartnersClickId]: edPartnersClickId,
      [EClientProductsRequestsDataKeys.additionalData]:
        {
          [EClientProductsRequestsDataKeys.subjectId]: subjectId,
        } || null,
    }
  }, [
    code,
    count,
    productId,
    productType,
    edPartnersClickId,
    funnel,
    lovkoProClickId,
    query,
    requestTarget,
    subjectId,
    referralCode,
    specialOfferCode,
  ])

  /* При сабмите отправляем данные */
  const onSubmit = useCallback(
    (values) => {
      setFormValuesOnSubmit(values)
      const data = { ...values, visit: getVisitInfo(SCHOOL_SITE_NAME) }
      const parentValue = data[EDataKeys.isParent]

      data[EDataKeys.isParent] = parentValue ? parentValue === 'true' : parentValue
      if (query.amoTag) {
        data[EClientProductsRequestsDataKeys.amoTag] = query.amoTag
      }
      dispatch(clientProductsRequestsThunks.send({ data }))
    },
    [dispatch, query.amoTag]
  )

  // если запрос прошел успешно отправляем цели в аналитику
  useReqAnalytics(requestTarget || productType, clientProductsRequestsLoading)

  const sendCode = useCallback(async () => {
    if (!isSendSuccess) return

    let step = EApplicationFormSteps.final

    if (!clientProductsRequestsEntity?.phoneConfirmed) {
      const payload = await dispatch(
        authPhoneCodeThunks.sendCode({ data: { phone: formValuesOnSubmit.phone } })
      )

      if (payload.error) {
        return
      }

      // Если нужно подтвердить телефон
      step = EApplicationFormSteps.code
    }

    // Шаг с запросом контактов родителя пока закрыли
    // if (formValuesOnSubmit[EDataKeys.isParent] === 'false' && aboutParent) {
    //   // Если выбран старшеклассник
    //   step = EApplicationFormSteps.parent
    // }

    pushToStep(router, {
      [EApplicationFormQuery.step]: step,
      [EApplicationFormQuery.requestId]: clientProductsRequestsEntity?.requestId,
      [ENoticePermissionQueryType.n_m_block]: 1,
    })
  }, [
    clientProductsRequestsEntity?.phoneConfirmed,
    clientProductsRequestsEntity?.requestId,
    formValuesOnSubmit,
    isSendSuccess,
    aboutParent,
  ])

  /* Пушим на определенный шаг при успешной отправке формы */
  useEffect(() => {
    sendCode()
  }, [sendCode])

  return {
    initialValues,
    onSubmit,
  }
}

export const useApplicationFormParentCore: IUseApplicationFormParentCore = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const profileLoading = useSelector(profileSelectors.loading)
  const clientProductsRequestsEntity = useSelector(clientProductsRequestsSelectors.entity)
  const [formValuesOnSubmit, setFormValuesOnSubmit] = useState({})
  const [submitted, setSubmitted] = useState(false)

  useResetProfileAndRequestLoadings()

  /* Если отправка прошла успешно */
  const isSendSuccess = useMemo(() => {
    return (
      submitted &&
      Object.keys(formValuesOnSubmit).length &&
      profileLoading === ELoading.fulfilled
    )
  }, [formValuesOnSubmit, profileLoading, submitted])

  /* Если происходит отправка данных */
  const isLoading = useMemo(() => profileLoading === ELoading.pending, [profileLoading])

  /* Формируем значение по умолчанию */
  const initialValues = {
    [EDataKeys.parentName]: '',
    [EDataKeys.parentPhone]: '',
  }

  /* При сабмите отправляем данные */
  const onSubmit = useCallback(
    async (data) => {
      setFormValuesOnSubmit(data)

      await dispatch(
        profileThunks.patch({
          data,
        })
      )

      setSubmitted(true)
    },
    [dispatch]
  )

  /* Пушим на определенный шаг при успешной отправке формы */
  useEffect(() => {
    if (!isSendSuccess) return

    pushToStep(router, {
      [EApplicationFormQuery.step]: EApplicationFormSteps.final,
      [EApplicationFormQuery.requestId]: clientProductsRequestsEntity?.requestId,
    })
  }, [clientProductsRequestsEntity?.requestId, isSendSuccess, router])

  return {
    initialValues,
    onSubmit,
    isLoading,
  }
}

export const useApplicationFormCodeCore: IUseApplicationFormCodeCore = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const timout = useSelector(authPhoneCodeSelectors.timeout)
  const clientRequestLoading = useSelector(clientProductsRequestsSelectors.loading)
  const { values, setFieldValue, submitForm, setErrors } = useFormikContext()
  const sendCodeLoading = useSelector(authPhoneCodeSelectors.loading)
  const sendCodeType = useSelector(authPhoneCodeSelectors.sendCodeType)
  const isSendCodeTypeTelegram = useMemo(() => {
    return sendCodeType?.sendCodeType?.name === 'TELEGRAM'
  }, [sendCodeType])

  useResetProfileAndRequestLoadings()

  const requestStep = useRequestStep()

  /* Получаем телефон из контекста формы */
  const phone = useMemo(() => {
    return getIn(values, EDataKeys.phone)
  }, [values])

  /* При вводе кода очищаем ошибки и при достижении нужного количества цифр, самбмитим форму */
  const onInputCode = useCallback(
    (_, codeValue) => {
      if (codeValue.length === codeLength) {
        setFieldValue(EDataKeys.code, codeValue)
        submitForm()
      }

      setErrors({})
    },
    [setErrors, setFieldValue, submitForm]
  )

  /* Если нет телефона в контексте формы, то отправляем на заявку */
  useEffect(() => {
    if (!phone) {
      router.replace(requestStep, undefined, { shallow: true, scroll: false })
    }
  }, [requestStep, phone, router])

  /* Получаем таймер */
  const { timeLeft, isTimeLeft } = useTimer({
    seconds: timout,
    secondsByMinutes: true,
  })

  /* Когда заканчивается время, ресетим его в сторе */
  useEffect(() => {
    if (isTimeLeft) {
      dispatch(authPhoneCodeActions.setTimeoutValue(0))
    }
  }, [dispatch, isTimeLeft])

  /* Метод запроса нового кода */
  const getNewCode = useCallback(
    (isForceSms?: boolean) => {
      dispatch(authPhoneCodeThunks.sendCode({ data: { phone, isForceSms } }))
    },
    [dispatch, phone]
  )

  /* Запрашиваем код при инициализации */
  useEffect(() => {
    if (phone && sendCodeLoading === ELoading.idle) {
      getNewCode()
    }
  }, [getNewCode, phone, sendCodeLoading])

  return {
    phone,
    requestStep,
    onInputCode,
    clientRequestLoading,
    timeLeft,
    isTimeLeft,
    getNewCode,
    isSendCodeTypeTelegram,
  }
}

/* Хук для регистрации на заведенные открытые уроки */
export const useRegistrToEvents = (
  id: string | number | undefined,
  requestTarget: string
): void => {
  const isAuth = useSelector(authSelectors.isAuth)
  const eventsIdLoading = useSelector(eventsIdSelectors.loading)
  const clientProductsRequestsLoading = useSelector(
    clientProductsRequestsSelectors.loading
  )
  const dispatch = useDispatch()

  /* Если пользователь авторизован, и регистрации на мероприятие еще не происходила то регистрируем */
  if (
    isAuth &&
    eventsIdLoading === ELoading.idle &&
    requestTarget === ETypeRequest.ege_lead_free_lesson &&
    clientProductsRequestsLoading === ELoading.fulfilled &&
    id
  ) {
    dispatch(
      eventsIdThunks.register({
        data: {
          id,
          visit: getVisitInfo(UCHEBA_SITE_NAME),
        },
      })
    )
  }
}

interface IUseRequestTags {
  (): {
    isMessengersForm: boolean
  }
}

export const useRequestTags: IUseRequestTags = () => {
  // const userTags = isBrowser && JSON.parse(localStorage.getItem('userTags') || '{}')
  // const isMessengersForm = useMemo(() => {
  //   return !!userTags?.[ERequestTags.ege_request_messenger]
  // }, [userTags])
  const isMessengersForm = false

  return {
    isMessengersForm,
  }
}

interface IUseMessengersLinks {
  (productIdParam?: number): {
    vk: string
    tg: string
    wa: string
  }
}
export const useMessengersLinks: IUseMessengersLinks = (productIdParam) => {
  const router = useRouter()
  const { requestTarget, withPayment, productId, product_id, funnel } = router.query

  const siteName = useSelector(siteNameSelectors.siteName)

  const domain = useMemo(() => {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (isBrowser) {
      if (window.location.host.includes('school')) {
        return process.env.NEXT_PUBLIC_URL as string
      }
    }

    return ''
  }, [isBrowser])

  const tg = useMemo(() => {
    const params = {
      scenario: EChatBotScenario.egeRequest,
      skip_request: '1',
      siteName,
    } as Record<string, any>

    if (requestTarget) {
      params.requestTarget = requestTarget

      params.scenario =
        scenariosByReqTargets[
          Array.isArray(requestTarget) ? requestTarget[0] : requestTarget
        ]
    }

    if (productId || productIdParam) {
      params.product_id = productId || product_id || productIdParam
    }

    if (withPayment) {
      params.with_payment = '1'
    }

    if (funnel) {
      params.funnel = funnel
    }

    return stringifyUrl(`${domain}/events/tg-subscribe/registration`, {
      set: {
        ...params,
      },
    })
  }, [siteName, requestTarget, productId, productIdParam, withPayment, product_id])

  const vk = useMemo(() => {
    const params = {
      scenario: EChatBotScenario.egeRequest,
      skip_request: '1',
      siteName,
    } as Record<string, any>

    if (requestTarget) {
      params.requestTarget = requestTarget

      params.scenario =
        scenariosByReqTargets[
          Array.isArray(requestTarget) ? requestTarget[0] : requestTarget
        ]
    }

    if (productId || productIdParam) {
      params.product_id = productId || product_id || productIdParam
    }

    if (withPayment) {
      params.with_payment = '1'
    }

    return stringifyUrl(`${domain}/events/vk-subscribe/registration`, {
      set: {
        ...params,
      },
    })
  }, [siteName, requestTarget, productId, productIdParam, withPayment, product_id])

  const wa = useMemo(() => {
    const params = {
      scenario: EChatBotScenario.egeRequest,
      skip_request: '1',
      siteName,
    } as Record<string, any>

    if (requestTarget) {
      params.requestTarget = requestTarget

      params.scenario =
        scenariosByReqTargets[
          Array.isArray(requestTarget) ? requestTarget[0] : requestTarget
        ]
    }

    if (productId || productIdParam) {
      params.product_id = productId || product_id || productIdParam
    }

    if (withPayment) {
      params.with_payment = '1'
    }

    return stringifyUrl(`${domain}/events/whatsapp-subscribe/registration`, {
      set: {
        ...params,
      },
    })
  }, [siteName, requestTarget, productId, productIdParam, withPayment, product_id])

  return {
    vk,
    tg,
    wa,
  }
}

export const useSelectProduct: IUseSelectProduct = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const entity = useSelector(clientProductsRequestsSelectors.entity)

  const requestId = useMemo(
    () => entity?.requestId || router.query[EApplicationFormQuery.requestId] || '',
    [entity?.requestId, router.query]
  )

  /* Отправляем на урл отправки заявки */
  const selectGroup = useCallback(
    (value, funnel, typeRequest, subjectId, eventsId, amoTag): void => {
      router.push(
        stringifyUrl(
          router.asPath,
          {
            update: {
              [EApplicationFormQuery.application]: 'true',
              [EApplicationFormQuery.productId]: value?.[EApplicationFormQuery.productId],
              [EApplicationFormQuery.count]: value?.[EApplicationFormQuery.count],
              [EApplicationFormQuery.withPayment]:
                value?.[EApplicationFormQuery.withPayment],
              [EApplicationFormQuery.funnel]: funnel,
              [EApplicationFormQuery.requestTarget]: typeRequest,
              [EApplicationFormQuery.subjectId]: subjectId,
              [EApplicationFormQuery.eventsId]: eventsId,
              [EApplicationFormQuery.step]: 'start',
              amoTag,
            },
          },
          undefined
        ),
        undefined,
        { shallow: true, scroll: false } // shallow сделал false, иначе step не удаляется из параметров при первом клике
      )
    },
    [router]
  )

  /* Отправляем на урл оплаты */
  const payProduct = useCallback(
    async (value) => {
      await dispatch(
        clientProductsRequestsThunks.patch({
          data: {
            ...value,
            requestId,
          },
        })
      )

      router.push(
        stringifyUrl('/payment', {
          set: {
            [EPaymentQuery.rightNow]: 'true',
            [EPaymentQuery.fromMarathon]:
              router.query[ERequestQuery.from] === ERequestSource.egeMarathon
                ? 'true'
                : '',
            [EPaymentQuery.requestId]: requestId,
          },
        })
      )
    },
    [dispatch, requestId, router]
  )

  return {
    selectGroup,
    payProduct,
  }
}
